<template>
  <v-text-field
    @change.native="search($event.target.value)"
    class
    clearable
    flat
    hide-details
    :prepend-inner-icon="prependIcon"
    :append-inner-icon="appendIcon"
    :label="label"
    clear-icon="mdi-close-circle-outline"
    :outlined="outline ? true : false"
  ></v-text-field>
</template>

<script>
export default {
  name: "SearchField",
  props: {
    searchFunction: { type: Function, required: true },
    prependIcon: { type: String, required: false },
    appendIcon: { type: String, required: false },
    label: { type: String, required: true },
    outline: { type: Boolean, required: false },
  },

  data() {
    return {};
  },

  methods: {
    search(data) {
      this.searchFunction(data);
    },
  },

  created() {},
};
</script>
